.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    margin-top: $offset4;
    margin-bottom: $offset15;
  }

  .content {
    display: flex;
    gap: $offset16;
    align-items: center;
    flex-wrap: wrap;

    .partner-block-wrapper {
      width: 606px;
    }

    .steps {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: $offset10;

      .steps-step {
        display: flex;
        position: relative;

        .li-content {
          width: 464px;
        }

        .steps-circle {
          width: 26px;
          height: 26px;
          line-height: 24px;
          border-radius: 50%;
          padding-right: 1px;
          border: 2px solid $blue700;
          margin-right: $offset10;
          display: inline-flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
        }

        &:nth-child(-n + 2)::after {
          content: '';
          width: 2px;
          left: 13px;
          top: 40px;
          position: absolute;
          height: 42px;
          background-color: $blue700;
        }

        &:nth-child(2)::after {
          height: 64px;
        }
      }
    }
  }
}
