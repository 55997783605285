.other-info-form {
  .other-info-form-actions {
    display: flex;
    justify-content: center;
    margin-top: $offset10;
  }

  .other-info-form-action {
    width: 240px;

    & + .other-info-form-action {
      margin-left: $offset6;
    }
  }

  .other-info-form-api-type {
    width: 392px;
  }
}
