@media screen and (max-width: $breakpointMobile) {
  .contact-info-form {
    margin-top: $offset11;

    .contact-info-form-row {
      display: flex;
      gap: 0;
      flex-wrap: wrap;
    }

    .contact-info-form-item {
      width: 100%;
    }

    .contact-info-form-btn-block {
      margin-top: $offset4;
      border-top: 1px solid $blue20;
      padding-top: $offset4;
    }

    .contact-info-form-btn {
      width: 100%;
      margin-top: $offset6;
    }
  }
}
