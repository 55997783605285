.become-partner-form-wrapper {
  margin: $offset24 auto 0;

  .become-partner-form-title,
  .become-partner-form-subtitle {
    margin-top: $offset4;
    text-align: center;
  }

  .become-partner-form {
    margin: $offset15 0 $offset16;
    padding: $offset16;
    background-color: $blue10;
    border-radius: $borderRadius6;

    .become-partner-form-steps {
      margin-bottom: $offset21;
    }

    .become-partner-form-form {
      display: flex;
      justify-content: center;
    }

    .become-partner-form-form-inner {
      width: 100%;
    }
  }
}
