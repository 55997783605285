.main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $offset16;
  margin-bottom: $offset24;

  .left-block {
    width: 100%;
    margin-right: $offset16;

    .advantages {
      display: flex;
      gap: $offset4;
      flex-direction: column;
      margin-top: $offset10;
      margin-bottom: $offset17;

      .advantage {
        display: flex;
        align-items: center;
        gap: $offset4;
      }
    }

    .button-wrapper {
      width: 340px;
    }
  }

  .video-wrapper {
    min-width: 628px;
    height: 578px;
    position: relative;
    border-radius: $borderRadius12;
    overflow: hidden;

    .video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50%;
      overflow: hidden;
    }
  }
}

.advantage-cards {
  display: flex;
  justify-content: space-between;
  gap: $offset24;
  margin-bottom: $offset24;

  .advantage-card {
    display: flex;
    flex-direction: column;

    .card-image {
      margin-bottom: $offset12;
    }
  }
}
