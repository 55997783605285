@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import '@frontend/design_system/src/globalStyles/variables.scss';

body,
html,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
menu,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button,
textarea,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: 'Inter', sans-serif;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

td {
  padding: 0;
}

/* Reset button styles */
button {
  padding: 0;
  border: none;
  background-color: transparent;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  color: $blue700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
  &:active,
  &:focus {
    outline: none;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.layout {
  padding-top: $offset16;
  min-height: 100vh;
  width: 100%;
  position: relative;

  .notification {
    position: absolute;
    right: 0;
  }
}
