.header {
  width: 100%;
  background-color: $blue900Master;
  height: $defaultHeaderHeight;
  position: fixed;
  z-index: 100;

  .header-content {
    display: flex;
    height: $defaultHeaderHeight;
    justify-content: space-between;
    align-items: center;

    .button-wrapper {
      width: 85px;
    }
  }
}
