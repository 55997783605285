@media screen and (max-width: $breakpointMobile) {
  .content {
    width: 100%;
    text-align: left;

    .button-wrapper {
      width: 100%;
      position: absolute;
      display: block;
      border-top: 1px solid $blue20;
      padding: $offset4;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
}
