@media screen and (max-width: $breakpointMobile) {
  .location-form {
    margin-top: $offset11;
    .location-form-row {
      display: flex;
      gap: $offset2;
      flex-wrap: wrap;
    }

    .location-form-item {
      width: 100%;
    }

    .location-form-btn-block {
      gap: $offset4;
      width: 100%;
      margin-top: 0;

      .location-form-btn {
        width: 50%;
        min-width: auto;
      }
    }
  }
}
