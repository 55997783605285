.location-form {
  .location-form-row {
    display: flex;
    gap: $offset6;
  }

  .location-form-item {
    width: 50%;
  }

  .location-form-btn-block {
    margin: $offset4 auto 0;
    gap: $offset6;
    width: 360px;
    display: flex;
    justify-content: center;
  }

  .location-form-btn {
    width: 100%;
  }
}
