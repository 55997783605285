@media screen and (max-width: $breakpointMobile) {
  .other-info-form {
    margin-top: $offset11;

    .other-info-form-api-type {
      width: 100%;
    }

    .other-info-form-actions {
      padding-top: $offset4;
      margin-top: $offset4;
    }

    .other-info-form-action {
      width: 50%;

      & + .other-info-form-action {
        margin-left: $offset4;
      }
    }
  }
}
