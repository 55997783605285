.contact-info-form {
  .contact-info-form-row {
    display: flex;
    gap: $offset6;
  }

  .password-checklist-wrapper {
    margin-top: $offset2;
  }

  .contact-info-form-item {
    width: 50%;
  }

  .contact-info-form-item-checkbox {
    border-top: 1px solid $blue20;
    padding-top: $offset4;
    margin: $offset4 0;
  }

  .contact-info-form-btn {
    margin: $offset12 auto 0;
    width: 400px;
  }
}
