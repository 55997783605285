@media screen and (max-width: $breakpointTablet) {
  .wrapper {
    .title {
      margin-bottom: $offset12;
    }

    .content {
      gap: $offset10;

      .partner-block-wrapper {
        margin: 0 auto;
      }
    }

    .li-content-title {
      margin-bottom: $offset2;
    }
  }
}

@media screen and (max-width: $breakpointMobile) {
  .wrapper {
    align-items: start;

    .title {
      margin-bottom: $offset6;
    }

    .content {
      width: 100%;
      flex-direction: column;
      align-items: start;

      .partner-block-wrapper {
        width: 100%;
      }

      .steps {
        gap: $offset6;
        .steps-step {
          .steps-circle {
            margin-right: $offset4;
          }

          .li-content {
            width: 100%;
          }

          &:nth-child(-n + 2)::after {
            content: '';
            top: 36px;
            height: 36px;
          }

          &:nth-child(2)::after {
            height: 58px;
          }
        }
      }
    }
  }
}
