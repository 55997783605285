@media screen and (max-width: $breakpointTablet) {
  .main {
    margin-bottom: $offset11;

    .left-block {
      margin-right: $offset10;
    }

    .video-wrapper {
      min-width: 336px;
      width: 100%;
      height: 364px;
      position: relative;
      border-radius: $borderRadius12;
      overflow: hidden;

      .video {
        position: absolute;
        transform: translate(-40%, -20%) scale(0.7);
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
  }

  .advantage-cards {
    gap: $offset6;
    flex-wrap: wrap;
    margin-bottom: $offset12;

    .advantage-card {
      width: 328px;

      .card-image {
        margin-bottom: $offset6;
      }
    }
  }
}

@media screen and (max-width: $breakpointMobile) {
  .main {
    flex-direction: column;
    margin-top: $offset6;

    .left-block {
      margin-right: 0;
      margin-bottom: 0;

      .advantages {
        margin: $offset6 0;
      }
    }

    .button-wrapper {
      margin-top: $offset6;
      width: 340px;
    }

    .video-wrapper {
      .video {
        position: absolute;
        transform: translate(-40%, -20%);
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
  }
  .advantage-cards {
    .advantage-card {
      .advantage-title {
        margin-bottom: $offset3;
      }
    }
  }
}
