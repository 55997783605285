.footer {
  height: 146px;
  width: 100%;
  background-color: $blue900Master;
  padding: $offset10 0;

  .wrapper {
    display: flex;
    gap: $offset6;

    .logo {
      width: 148px;
      height: 24px;
    }

    .trademark {
      width: 555px;
      text-align: left;
    }
  }
}
