.become-partner-success-modal {
  width: 622px;

  .become-partner-success-modal-header {
    height: 232px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $borderRadius6 $borderRadius6 0 0;
    text-align: center;
    overflow: hidden;
  }

  .become-partner-success-background-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .become-partner-success-modal-content {
    padding: $offset6;
    display: flex;
    flex-direction: column;
    gap: $offset3;

    p + p {
      margin-top: $offset5;
    }
  }

  .become-partner-success-modal-title {
    z-index: 1;
  }
}
