@media screen and (max-width: $breakpointTablet) {
  .become-partner-form-wrapper {
    margin-top: $offset12;
    .become-partner-form {
      margin-top: $offset12;
      padding: $offset12 $offset4;
    }
  }
}

@media screen and (max-width: $breakpointMobile) {
  .become-partner-form-wrapper {
    .become-partner-form-title,
    .become-partner-form-subtitle {
      text-align: left;
    }

    .become-partner-form {
      padding: $offset6 $offset4;
      border-radius: 0;
      margin-top: $offset6;

      .become-partner-form-steps {
        margin: $offset6 0;
      }

      .become-partner-form-form {
        width: 100%;
      }

      .become-partner-form-form-inner {
        width: 100%;
      }
    }
  }
}
