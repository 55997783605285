@media screen and (max-width: $breakpointMobile) {
  .footer {
    height: auto;
    padding: $offset4 0;

    .wrapper {
      flex-direction: column;

      .logo {
        width: 120px;
        height: 20px;
      }

      .trademark {
        width: 100%;
      }
    }
  }
}
