.content {
  width: 624px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: $offset6;

  .image {
    margin: $offset12 auto;
  }
}
